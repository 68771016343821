import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ policy and procedures around risk management.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`See Purpose.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`To clarify Data Migrators’ policy on Risk Management, and the processes that support
that policy.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This Policy applies to all Company officers, employees, partners, and
contractors to facilities and systems controlled by the Company. The
policy extends to all current and future activities, and new opportunities.`}</p>
    <p>{`Where necessary, more detailed risk management policies and procedures will
be developed to cover specific areas of the Company’s operations, such as
financial management and business management.`}</p>
    <h2>{`Policy`}</h2>
    <h3>{`Recognition of the need for risk management`}</h3>
    <p>{`Data Migrators recognises the need for risk management to feature as a
consideration in strategic and operational planning, day-to-day management
and decision making at all levels in the organisation.`}</p>
    <h3>{`A commitment to implement risk management effectively`}</h3>
    <p>{`Data Migrators is committed to managing and minimising risk by identifying,
analysing, evaluating and treating exposures that may impact on the Company
achieving its objectives and/or the continued efficiency and effectiveness of
its operations. Data Migrators will incorporate risk management into its
organisational planning and decision-making processes. Risk management must
also be included as a consideration in sectional and operational planning as
a delegated line management responsibility. Data Migrators staff must implement
risk management according to relevant legislative requirements and appropriate
risk management standards.`}</p>
    <h3>{`A commitment to training and knowledge development in the area of risk management`}</h3>
    <p>{`Data Migrators is committed to ensuring that all staff, particularly those with
management, advisory, and decision making responsibilities, obtain a sound understanding
of the principles of risk management and the requisite skills to implement risk
management effectively.`}</p>
    <h3>{`A commitment to monitor performance and review progress in risk management`}</h3>
    <p>{`Data Migrators will regularly monitor and review the progress being made in
developing an appropriate culture of risk management and the effective
implementation of risk management strategies throughout the organisation as
a basis for continuous improvement.`}</p>
    <h2>{`Principles`}</h2>
    <h3>{`Responsibility for Risk Management`}</h3>
    <p>{`Risk must first and foremost be managed at the corporate level as part of the Company’s
good governance and corporate management processes. Risk management is considered an
integral part of all management and decision-making functions within Data Migrators.
The responsibility for the identification of risk and the implementation of control
strategies and follow up remains a delegated line management responsibility. All
stakeholders have a significant role in the management of risk. This role may range
from initially identifying and reporting risks associated with their own jobs to
participation in the risk management process. Data Migrators Directors will facilitate
the introduction and monitoring of risk management into key areas of Data Migrators’
activities`}</p>
    <h3>{`Objectives of and Rationale for Risk Management`}</h3>
    <ol>
      <li parentName="ol">{`Data Migrators, in its need for risk management, aims to:`}
        <ul parentName="li">
          <li parentName="ul">{`facilitate and review risk management activities across the institution through Data Migrators Directors;`}</li>
          <li parentName="ul">{`integrate risk management into the management culture of the Company; and`}</li>
          <li parentName="ul">{`foster an environment where staff assume responsibility for managing risks.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`To secure its commitment to implement risk management effectively, Data Migrators aims to:`}
        <ul parentName="li">
          <li parentName="ul">{`implement risk management across all aspects of the Company in accordance with best practice guidelines.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`To secure its commitment to training and knowledge development in the area of risk management, Data Migrators aims to:
ensure that performance in risk management is a consideration in the Company’s performance management systems; and
ensure that staff and other stakeholders have access to appropriate information, training and other development opportunities in the area of risk management.`}</li>
      <li parentName="ol">{`To secure its commitment to monitoring performance and reviewing progress, Data Migrators aims to:`}
        <ul parentName="li">
          <li parentName="ul">{`ensure that appropriate monitoring, review and reporting processes are in place in the area of risk management.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`The objectives of risk management are to:`}
        <ul parentName="li">
          <li parentName="ul">{`provide a structured basis for strategic, tactical and operational planning across Data Migrators;`}</li>
          <li parentName="ul">{`enhance Data Migrators’ governance and corporate management processes;`}</li>
          <li parentName="ul">{`enable Data Migrators to effectively discharge its statutory and legislative financial management responsibilities;`}</li>
          <li parentName="ul">{`provide a practical framework for managers to assess risks inherent in the decisions they take;`}</li>
          <li parentName="ul">{`assist and motivate decision makers, at all levels, to make good and proactive management decisions that do not expose Data Migrators to unacceptable levels of risk of unfavourable events occurring which adversely impact on the attainment of organisational goals; encourage and commit decision makers to identify sound business opportunities that will benefit Data Migrators without exposing the Company to unacceptable levels of risk;`}</li>
          <li parentName="ul">{`minimise the risks of not identifying sound business opportunities;`}</li>
          <li parentName="ul">{`protect Data Migrators from unacceptable costs or losses associated with its operations;`}</li>
          <li parentName="ul">{`safeguarding of Data Migrators’ resources - its people, finance, property and reputation;`}</li>
          <li parentName="ul">{`assist Data Migrators in achieving its strategic objectives; and`}</li>
          <li parentName="ul">{`create an environment where all staff assume responsibility for risk management`}</li>
        </ul>
      </li>
    </ol>
    <h2>{`Procedures`}</h2>
    <h3>{`Whole of Company Risk Management Process`}</h3>
    <p>{`Risk must first and foremost be managed at the corporate level as part of Data Migrators’
good governance and corporate management processes. This process, coordinated and
facilitated by Data Migrators Directors, will involve the following key steps:`}</p>
    <ol>
      <li parentName="ol">{`an annual risk identification exercise undertaken by Data Migrators Directors,
which involves assessment of the consequence and likelihood of risk, the development
and/or review of individual risk management plans for the risks identified which
exceed the Organisation’s defined acceptable risks;`}</li>
      <li parentName="ol">{`wherever practicable the inclusion of a Risk Management Assessment for all
business activities;`}</li>
      <li parentName="ol">{`the incorporation of risk management into Company strategic planning, and
operational and resource management planning processes;`}</li>
      <li parentName="ol">{`annual review of the risk management activities by Data Migrators Directors;`}</li>
      <li parentName="ol">{`at least annual assessment by Data Migrators Directors of action taken in
respect of risk management;`}</li>
      <li parentName="ol">{`ensure risk management processes are incorporated into the quality assurance
and improvement systems of the Company;`}</li>
      <li parentName="ol">{`clearly define and document escalation procedures for risk management;`}</li>
      <li parentName="ol">{`ensure a consistency in approach of responses to the same risk by different
sections of the Company;`}</li>
      <li parentName="ol">{`document all risks with a potentially high impact, as assessed on the basis
of their likely occurrence or impact; and`}</li>
      <li parentName="ol">{`test documented risk management procedures at appropriate intervals.`}</li>
    </ol>
    <h4>{`Risk Management as a Delegated Line Management Responsibility`}</h4>
    <p>{`Risk management is a delegated line management responsibility. It is the
responsibility of all line managers to continually monitor their areas of
responsibility to ensure that risks are identified and managed. Line managers
should ensure that a contribution is made to the whole-of-Company risk
management process, on behalf of their areas of responsibility, that identifies
risks at all levels.`}</p>
    <p>{`The sharing of documented responses to risks and knowledge of risk management
principles and procedures will be fostered between line managers to ensure
consistency across the Company.`}</p>
    <p>{`On an annual basis, line managers should review all activities to ensure that
any unacceptable risk exposures are identified and managed at an appropriate
level. All operational sections will be required to report on risk management
as part of the institution’s annual operational and resource management process.`}</p>
    <h4>{`Individual`}</h4>
    <p>{`Each employee or other stakeholder throughout the Company has a role in the
risk management process and is responsible for actively participating in the
risk management process as appropriate to their position within the Company.`}</p>
    <h4>{`Management of Risks Associated with New Opportunities`}</h4>
    <p>{`In addition to the risks that already exist, the Company is continually
exposed to new risks particularly from the introduction of new activities. The
new risks should be incorporated into the initial planning and assessment
processes conducted prior to undertaking the activity and, subsequently, into the
annual risk management assessment at the appropriate level(s) of activity and
management.`}</p>
    <h3>{`Principles to be Applied`}</h3>
    <p>{`The principles of risk management shall be applied to all areas of risk exposure,
insurable and non-insurable, and shall include, but not be limited to the following
areas:`}</p>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Insurable Risks`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Non-Insurable Risks`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><ul parentName="td">
              <li parentName="ul">{`Insurable workplace health and safety risks`}</li>
              <li parentName="ul">{`Insurable fraud and corruption prevention activities`}</li>
              <li parentName="ul">{`Unauthorised use of resources which represent an insurable risk`}</li>
              <li parentName="ul">{`Reputation and image as an insurable risk`}</li>
              <li parentName="ul">{`Fire prevention measures and security precautions`}</li>
              <li parentName="ul">{`Property loss and damage`}</li>
              <li parentName="ul">{`Computer security`}</li>
              <li parentName="ul">{`Vehicle fleet management`}</li>
              <li parentName="ul">{`Professional negligence`}</li>
              <li parentName="ul">{`Other liability exposures`}</li>
              <li parentName="ul">{`Legal liability`}</li>
            </ul></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><ul parentName="td">
              <li parentName="ul">{`Non-insurable workplace health and safety risks`}</li>
              <li parentName="ul">{`Non-insurable fraud and corruption prevention activities`}</li>
              <li parentName="ul">{`Unauthorised use of resources which represent a non-insurable risk`}</li>
              <li parentName="ul">{`Reputation and image as a non-insurable risk`}</li>
              <li parentName="ul">{`Crisis contingency planning and disaster recovery`}</li>
              <li parentName="ul">{`Accounting controls that are not cost effective`}</li>
              <li parentName="ul">{`Loss of key staff and intellectual property`}</li>
              <li parentName="ul">{`The impact of globalisation on risk exposures`}</li>
              <li parentName="ul">{`Management system inadequacies and poor work quality`}</li>
              <li parentName="ul">{`Failure or disruption of a major income source or investment`}</li>
            </ul></td></tr></tbody></table>
    <h3>{`Review`}</h3>
    <p>{`Data Migrators Directors will regularly monitor and review the  progress being
made in developing an appropriate culture of risk management and the effective
implementation of risk management strategies throughout the organisation.`}</p>
    <h3>{`Guidance on Acceptable Risk`}</h3>
    <p>{`Through its monitoring, review and reporting functions, Data Migrators’ Directors
will ensure that the Company maintains a consistent approach to its assessment
of acceptable risk.`}</p>
    <h3>{`Documentation`}</h3>
    <p>{`Each stage of the risk management process shall be appropriately documented. The
extent of documentation required is dependent on the nature of the risk.
Documentation will be controlled so as to inform part of an auditable quality
management process.`}</p>
    <h3>{`Compliance`}</h3>
    <p>{`A representation and compliance statement should be provided by each project manager
as formal acknowledgement of their responsibility to comply with risk management
policies and procedures. Each employee should have included in their Position
Description a responsibility for risk management, and Annual Performance Appraisals
should include an appropriate assessment thereof.`}</p>
    <h3>{`Staff Development`}</h3>
    <p>{`Management shall ensure that staff have available to them appropriate information and
training opportunities in risk management as appropriate to their position and role
within Data Migrators.`}</p>
    <h2>{`References`}</h2>
    <p>{`None.`}</p>
    <h2>{`Related Documents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "risk_assessment_policy"
        }}>{`Risk Assessment Policy`}</a></li>
    </ul>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      